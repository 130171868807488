<template>
  <v-theme-provider with-background :theme="isDark ? 'dark' : 'light'">
    <v-app>
      <TopNav :nav-items="navItems">
        <template #search>
          <AutoComplete v-model="currentInstrument" class="w-80 max-sm:w-full"></AutoComplete>
        </template>
      </TopNav>

      <v-main>
        <router-view v-if="user" v-slot="{ Component }">
          <keep-alive :max="10">
            <TransitionPage>
              <component :is="Component" :key="keyGen" />
            </TransitionPage>
          </keep-alive>
        </router-view>
      </v-main>
    </v-app>
  </v-theme-provider>

  <PWAPrompt></PWAPrompt>
</template>

<script setup lang="ts">
import { ref, computed, inject } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useMsal } from "@wbim/msal-compositional"
import { TopNav } from "@wbim/ui-base-components"

import PWAPrompt from "@/components/common/PWAPrompt.vue"
import AutoComplete, { type CompleteItem } from "@/components/common/AutoComplete.vue"
import TransitionPage from "@/TransitionPage.vue"

const { isDark } = inject("theme") as { isDark: boolean }

const { instance } = useMsal()
if (!instance) {
  throw new Error("Msal instance is not initialized")
}
const user = instance.getActiveAccount()
const route = useRoute()
const router = useRouter()

const currentInstrument = ref<CompleteItem>()
const navItems = [
  {
    label: "Top down",
    items: [
      {
        label: "Economic Summary",
        command() {
          router.push({ name: "top-down-econ-summary" })
        }
      },
      {
        label: "TAA Scorecard",
        command() {
          router.push({ name: "top-down-taa-scorecard" })
        }
      }
    ]
  },
  {
    label: "Bottom Up",
    items: [
      {
        label: "Sovereign",
        command() {
          router.push({ name: "sovereign-home" })
        }
      },
      {
        label: "Corporate",
        command() {
          router.push({ name: "corp-home" })
        }
      }
    ]
  },
  {
    label: "Universe",
    command() {
      router.push({ name: "investable-universe" })
    }
  },
  {
    label: "Portfolios",
    command() {
      router.push({ name: "portfolio-management" })
    }
  }
]

const keyGen = computed(() => {
  const { meta } = route
  if (typeof meta?.keyGen === "function") {
    return meta.keyGen(route)
  }
  return route.fullPath
})
</script>
