import { keyBy, isEmpty } from "lodash-es"

import type APIRepository from "@/utils/APIRepository"
import throwExpression from "@/utils/throwExpression"

export enum AssetType {
  HardCurrency = "HC",
  LocalCurrency = "LC"
}

export enum Region {
  Africa = "Africa",
  Asia = "Asia",
  EasternEurope = "Eastern Europe",
  LatinAmerica = "Latin America",
  MiddleEast = "Middle East"
}

export enum BetaBucketHc {
  High = "High",
  Medium = "Medium",
  Low = "Low"
}

export enum BetaBucketLc {
  High = "High",
  Low = "Low",
  Frontier = "Frontier",
  Developed = "Developed"
}

export type SovereignUniverseEntry = {
  isoNumericId: number
  isoAlpha2: string
  isoAlpha3: string
  currency: string
  name: string
  region: Region
  betaBucketHc?: BetaBucketHc
  betaBucketLc?: BetaBucketLc
}

export type BetaBucketEntryHc = {
  EMDISOCode: string
  EMDBetaBucket: BetaBucketHc
  EMDRiskCountry: string
  EMDRegion: string
}

export type BetaBucketEntryLc = {
  EMDISOCode: string
  EMDBetaBucket_LC: BetaBucketLc
  EMDRiskCountry: string
  EMDRegion: string
  Currency: string
}
export default class SovereignUniverse extends Array<SovereignUniverseEntry> {
  private readonly sovUniverseAlpha2Map: Record<string, SovereignUniverseEntry> = {}
  private readonly sovUniverseAlpha3Map: Record<string, SovereignUniverseEntry> = {}
  private readonly betaBucketHcMap: Record<string, BetaBucketEntryHc> = {}
  private readonly betaBucketLcMap: Record<string, BetaBucketEntryLc> = {}

  constructor(
    universe: SovereignUniverseEntry[],
    betaBucketHcList: BetaBucketEntryHc[],
    betaBucketLcList: BetaBucketEntryLc[]
  ) {
    super()

    this.betaBucketHcMap = keyBy(betaBucketHcList, (d) => d.EMDISOCode)
    this.betaBucketLcMap = keyBy(betaBucketLcList, (d) => d.EMDISOCode)
    universe.forEach((entry) => {
      entry.betaBucketHc = this.betaBucketHcMap[entry.isoAlpha3]?.EMDBetaBucket
      entry.betaBucketLc = this.betaBucketLcMap[entry.isoAlpha3]?.EMDBetaBucket_LC
      entry.currency = this.betaBucketLcMap[entry.isoAlpha3]?.Currency
    })
    this.sovUniverseAlpha2Map = keyBy(universe, (d) => d.isoAlpha2)
    this.sovUniverseAlpha3Map = keyBy(universe, (d) => d.isoAlpha3)
    //iso2 was used in URL
    this.push(...universe.filter((d) => !!d.isoAlpha2))

    Object.setPrototypeOf(this, SovereignUniverse.prototype)
  }

  //see https://javascript.info/extend-natives
  static get [Symbol.species]() {
    return Array
  }

  static async load(apiRepo: APIRepository) {
    const sovUniverse: SovereignUniverseEntry[] = await apiRepo.requestBlobCSV("sov-universe/latest.csv")
    const snapshotHc: BetaBucketEntryHc[] = await apiRepo.requestBlobCSV("enfusion-reports/Beta_Buckets_HC.csv")
    const snapshotLc: BetaBucketEntryLc[] = await apiRepo.requestBlobCSV("enfusion-reports/Beta_Buckets_LC.csv")
    return new SovereignUniverse(sovUniverse, snapshotHc, snapshotLc)
  }

  fromAlpha2(alpha2: string) {
    return this.sovUniverseAlpha2Map[alpha2] ?? throwExpression(`Cannot find ${alpha2} in universe`)
  }

  fromAlpha3(alpha3: string) {
    return this.sovUniverseAlpha3Map[alpha3] ?? throwExpression(`Cannot find ${alpha3} in universe`)
  }

  code2To3(code2: string) {
    return this.fromAlpha2(code2).isoAlpha3
  }

  code3To2(code3: string) {
    return this.fromAlpha3(code3).isoAlpha2
  }

  get hardCurrencyList() {
    return this.filter((d) => !!d.betaBucketHc)
  }

  get localCurrencyList() {
    return this.filter((d) => !!d.betaBucketLc)
  }

  filterUniverseHc(region?: Region, betaBucket?: BetaBucketHc) {
    return this.hardCurrencyList
      .filter((d) => (isEmpty(region) ? true : d.region === region))
      .filter((d) => (isEmpty(betaBucket) ? true : d.betaBucketHc === betaBucket))
  }

  filterUniverseLc(region?: Region, betaBucket?: BetaBucketLc) {
    return this.localCurrencyList
      .filter((d) => (isEmpty(region) ? true : d.region === region))
      .filter((d) => (isEmpty(betaBucket) ? true : d.betaBucketLc === betaBucket))
  }
}
