<template>
  <div v-if="fundingData && fundingData.length > 0" style="overflow: hidden; position: relative">
    <div class="section-header"></div>

    <div
      class="content-card"
      :style="{ overflow: 'auto', height: $vuetify.display.xs ? 'auto' : '360px', position: 'relative' }"
    >
      <div v-if="meta.financials === 'Y'">
        <highcharts :options="chartOptionsFundingStructure"></highcharts>
      </div>

      <div v-else class="info-section">
        <h4 class="info-section-title">Debt Summary</h4>
        <div class="info-grid">
          <div v-for="[key, , value] in fundingData.filter((d) => typeof d[2] === 'number')" :key="key">
            <div class="text-overline text-grey-darken-1 mb-2" style="font-weight: 500; line-height: 1.2">
              {{ key }}
            </div>

            <div class="text-h6 font-weight-thin">
              {{
                fmtNum(value, 0, ["DEBT TO BOOK CAPITALZATION", "DEBT TO EQUITY"].includes(key.toUpperCase().trim()))
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <span class="footnote">Currency:{{ meta.currency }}; Units: {{ meta.units }} when applicable</span>
  </div>
</template>

<script>
import Chart from "@wbim/highcharts-themed"

import EventBus from "@/utils/eventbus.js"
import { fmtNum } from "@/utils/vmMethods.js"

export default {
  components: { highcharts: Chart },
  inject: ["theme"],
  props: {
    expandedView: { type: Boolean, default: false }
  },

  data() {
    return {
      fundingData: [],
      meta: {}
    }
  },

  computed: {
    totalRow() {
      return this.fundingData.find((d) => (d[0] ?? "").trim() === "Total")
    },
    dataRows() {
      return this.fundingData.filter((d) => (d[0] ?? "").trim() !== "Total" && !isNaN(d[2]) && typeof d[2] === "number")
    },
    chartOptionsFundingStructure() {
      return {
        chart: { type: "pie", height: 316, borderWidth: 0 },
        title: { text: "Funding Structure" },
        tooltip: { enabled: false },
        plotOptions: {
          pie: {
            dataLabels: {
              // alignTo: "plotEdges",
              enabled: true,
              useHTML: true,
              style: {
                fontWeight: "normal"
              },
              formatter() {
                const total = (this.series.yData ?? []).reduce((acc, cur) => acc + cur, 0)
                const pct = ((this.y / total) * 100).toFixed(0) + "%"
                return `${this.key}: ${pct}<br> <span style="font-size:16px">${this.y.toFixed(0)} </span>`
              }
            }
          }
        },
        series: [
          {
            name: "Funding Structure",
            data:
              this.totalRow === undefined
                ? []
                : this.dataRows.map((d) => ({
                    name: d[0],
                    y: d[2],
                    dataLabels: { distance: d[2] / this.totalRow[2] > 0.2 ? "-30%" : 8 }
                  }))
          }
        ]
      }
    }
  },

  async mounted() {
    EventBus.$on("reload-funding", (data) => {
      this.meta = data.meta
      this.fundingData = data.fundingData
    })
  },
  methods: {
    fmtNum
  }
}
</script>

<style>
.info-section,
.info-section .text-overline {
  font-family: "Roboto Condensed" !important;
}
.info-section-title {
  margin: 8px 0;
  text-align: center;
  font-weight: normal;
  font-size: 18px;
}
.footnote {
  position: absolute;
  bottom: 4px;
  left: 16px;
  font-style: italic;
  font-size: 10px;
  font-weight: 400;
  color: #9e9e9e;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  grid-gap: 16px;
  grid-auto-flow: row;
  overflow: auto;
  position: relative;
}
</style>
