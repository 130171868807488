<template>
  <AutoComplete
    :suggestions="completeItems"
    :pt="{
      root: 'border !border-gray-200 rounded-md dark:!border-gray-600'
    }"
    option-label="text0"
    @option-select="goto($event.value)"
    @complete="search($event.query)"
    @focus="focus"
    size="small"
    auto-option-focus
    dropdown
  >
    <template #dropdownicon>
      <i class="pi pi-search"></i>
    </template>
    <template #option="{ option }">
      <div class="w-full flex items-center justify-between text-sm">
        <div class="w-64 truncate">{{ option.text0 }}</div>
        <div>
          <span
            v-if="option.type === 'country'"
            class="region-flag flag-icon"
            :class="`flag-icon-${option.value.toLowerCase()}`"
          ></span>
          <span v-else class="font-weight: bold">{{ option.ticker }}</span>
        </div>
      </div>
    </template>
  </AutoComplete>
</template>

<script lang="ts">
export type CompleteItem = {
  type: "country" | "corp"
  text0: string
  text: string
  ticker?: string
  value: string
}
</script>

<script setup lang="ts">
import { useRouter } from "vue-router"
import { inject, onBeforeMount, ref, nextTick } from "vue"
import AutoComplete from "primevue/autocomplete"

import { loadCorpIssuers } from "@/apis/corp.js"
import type APIRepository from "@/utils/APIRepository"
import type SovereignUniverse from "@/utils/SovereignUniverse"

const router = useRouter()
const apiRepo = inject<APIRepository>("apiRepo")!
const sovereignUniverse = inject<SovereignUniverse>("sovereignUniverse")!

const completeItems = ref<CompleteItem[]>([])
const regions = ref<CompleteItem[]>([])
const corpData = ref<CompleteItem[]>([])

onBeforeMount(async () => {
  regions.value = sovereignUniverse.map((r) => ({
    type: "country",
    text0: r.name,
    text: r.name + " - " + r.isoAlpha2,
    value: r.isoAlpha2 ?? ""
  }))

  const issuerDict = await loadCorpIssuers(apiRepo, true)
  corpData.value = Object.values(issuerDict)
    .map(
      //eslint-disable-next-line
      (d: any) =>
        ({
          type: "corp",
          text0: d.issuer_name ?? "",
          text: (d.issuer_name ?? "") + " - " + d.ticker,
          value: String(d.issuer_id) ?? "",
          ticker: d.ticker
        }) as CompleteItem
    )
    .filter((d) => d.value)
})

function rankItem(d: CompleteItem, query: string) {
  const vv = query.toUpperCase()
  const text = d.text.toUpperCase()
  const value = d.value.toUpperCase()
  //only meaningful to corp
  const ticker = d?.ticker ?? ""

  let rank = 0
  if (value === vv || ticker === vv || text === vv) {
    rank = 100
  } else if (value.startsWith(vv) || ticker.startsWith(vv) || text.startsWith(vv)) {
    rank = 10
  } else if (text.includes(vv) || ticker.includes(vv)) {
    rank = 1
  }
  return rank
}

function search(query: string) {
  if (!query) {
    completeItems.value = regions.value
    return
  }
  completeItems.value = [...corpData.value, ...regions.value]
    //eslint-didsable-next-line
    .map((d) => ({ ...d, rank: rankItem(d, query) }))
    .filter((d) => d.rank > 0)
    .sort((a, b) => b.rank - a.rank)
}

//workaround to clear the input when refocus
function focus(event: Event) {
  nextTick(() => {
    const target = event.target as HTMLInputElement
    target.value = ""
  })
}

function goto(item: CompleteItem) {
  const { value, type } = item
  if (type === "corp") {
    router.push({ name: "corp-overview", params: { issuer: value } })
  } else {
    router.push({ name: "econ-overview", params: { region: value } })
  }
}
</script>

<style scoped>
.region-flag {
  width: 40px;
  height: 28px;
  pointer-events: none;
}
</style>
