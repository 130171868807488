<template>
  <canvas ref="globe" />
</template>

<script>
import { feature } from "topojson-client"
import * as d3 from "d3"

import versor from "@/utils/versor.js"

function drag(projection) {
  let v0, q0, r0, a0
  function dragstarted(event) {
    v0 = versor.cartesian(projection.invert(d3.pointer(event, this)))
    q0 = versor((r0 = projection.rotate()))
  }
  function dragged(event) {
    const pt = d3.pointer(event, this)
    const v1 = versor.cartesian(projection.rotate(r0).invert(pt))
    const delta = versor.delta(v0, v1)
    let q1 = versor.multiply(q0, delta)
    if (pt[2]) {
      const d = (pt[2] - a0) / 2
      const s = -Math.sin(d)
      const c = Math.sign(Math.cos(d))
      q1 = versor.multiply([Math.sqrt(1 - s * s), 0, 0, c * s], q1)
    }
    projection.rotate(versor.rotation(q1))
    if (delta[0] < 0.7) dragstarted.apply(this, [event, this])
  }
  return d3.drag().on("start", dragstarted).on("drag", dragged)
}

function getInterpolates(countries) {
  let r1,
    p1,
    r2 = [0, 0, 0],
    p2 = [0, 0]

  return (code) => {
    const country = countries.find((d) => String(d.id) === code)
    r1 = r2
    p1 = p2
    p2 = d3.geoCentroid(country)
    r2 = [-p2[0], 0 - p2[1], 0]
    const ip = d3.geoInterpolate(p1, p2)
    const iv = versor.interpolate(r1, r2)
    return [country, ip, iv, p1, p2]
  }
}

function init(canvas, width, height, world, autospin, showGraticule) {
  if (!canvas) return
  let heatmapData = {}
  const sphere = { type: "Sphere" }
  const extend = [
    [20, 20],
    [width - 20, height - 20]
  ]
  const ratio = window.devicePixelRatio
  canvas.style.width = width + "px"
  canvas.style.height = height + "px"
  canvas.width = ratio * width
  canvas.height = ratio * height
  const ctx = canvas.getContext("2d")
  ctx.scale(ratio, ratio)
  const projection = d3.geoOrthographic().fitExtent(extend, sphere)
  const path = d3.geoPath(projection, ctx)
  const graticule = d3.geoGraticule().step([20, 20])()
  const land = feature(world, world.objects.land)
  const countries = feature(world, world.objects.countries).features
  const glowFn = getEarthGlow(width, height)

  function getEarthGlow(width, height) {
    let canvas
    return () => {
      if (canvas) return canvas
      const glowCanvas = document.createElement("canvas")
      glowCanvas.width = width
      glowCanvas.height = height
      const ctx = glowCanvas.getContext("2d")
      const path = d3.geoPath(projection, ctx)

      ctx.beginPath()
      path(sphere)
      ctx.strokeStyle = "#333"
      ctx.fillStyle = "#b7ccdf"
      ctx.lineWidth = 0.5
      ctx.shadowColor = "#999"
      ctx.shadowBlur = 16
      ctx.stroke()
      ctx.fill()

      return ctx.canvas
    }
  }

  function render(country, arc) {
    ctx.clearRect(0, 0, width, height)
    ctx.drawImage(glowFn(), 0, 0)
    ctx.beginPath()
    path(land)
    ctx.fillStyle = "#fbf8f4"
    ctx.fill()

    countries.map((country) => {
      ctx.beginPath()
      path(country)
      if (!country.id) return

      const numId = country.id.padStart(3, "0")
      ctx.fillStyle = heatmapData[numId] ?? "#fbf8f4"
      ctx.fill()
    })

    // show country border
    if (country) {
      ctx.beginPath()
      ctx.strokeStyle = "#313131"
      path(country)
      ctx.stroke()
    }

    if (arc) {
      ctx.beginPath()
      path(arc)
      ctx.lineWidth = 1
      ctx.stroke()
    }

    if (showGraticule) {
      ctx.beginPath()
      path(graticule)
      ctx.strokeStyle = "#666"
      ctx.lineWidth = 0.3
      ctx.stroke()
    }

    return ctx.canvas
  }

  function drawLabel(ctx, name, [x, y]) {
    if (!name) return
    const curStyle = ctx.fillStyle
    //draw circle to make small countries visible on the map
    ctx.moveTo(x, y)
    ctx.fillStyle = "rgba(51, 102, 51, 1)"
    ctx.beginPath()
    ctx.arc(x, y, 2, 0, 2 * Math.PI)
    ctx.fill()
    ctx.fillStyle = "#333"
    ctx.font = "Bold 14px 'Arial Narrow'"
    ctx.textAlign = "left"
    ctx.textBaseline = "middle"
    ctx.fillText(name, x + 3, y)
    ctx.fillStyle = curStyle
  }

  function rotating(elapsed) {
    projection.rotate([elapsed * 0.01, -15])
    render()
  }

  const interpolates = getInterpolates(countries)
  const autorotate = d3.timer(rotating)
  let currentCountry
  if (!autospin) autorotate.stop()

  d3.select(canvas)
    .call(drag(projection).on("drag.render", render).on("end.render", render))
    .on("mousemove", function (event) {
      autorotate.stop()
      const pos = projection.invert(d3.pointer(event, this))
      const c = countries.find((c) => d3.geoContains(c, pos))
      if (c) {
        render(c)
        drawLabel(ctx, c?.properties?.name, projection(d3.geoCentroid(c)))
      }
    })
    .on("mouseout", function () {
      if (autospin) {
        autorotate.restart(rotating, 2000)
      }
      if (currentCountry) {
        const c = countries.find((d) => d.id === currentCountry)
        if (c) {
          render(c)
          drawLabel(ctx, c?.properties?.name, projection(d3.geoCentroid(c)))
        }
      }
    })
  return {
    animate: function (codeTo, codeFrom) {
      if (autorotate) autorotate.stop()
      if (!codeTo) return
      currentCountry = codeTo
      const [countryTo, ip, iv, p1, p2] = interpolates(codeTo)
      const countryToName = countryTo?.properties?.name
      const countryFrom = countries.find((c) => String(c.id) === codeFrom)
      const countryFromName = countryFrom?.properties?.name

      d3.select(canvas)
        .transition()
        .duration(1500)
        .tween("rotate", () => (t) => {
          projection.rotate(iv(t))
          render(countryTo, { type: "LineString", coordinates: [p1, ip(t)] })
          drawLabel(ctx, countryFromName, projection(p1))
        })
        .transition()
        .duration(500)
        .tween("render", () => (t) => {
          render(countryTo, { type: "LineString", coordinates: [ip(t), p2] })
          drawLabel(ctx, countryToName, [width / 2, height / 2])
        })
        .on("end", () => autospin && autorotate.restart(rotating, 3000))
    },
    setHeatmapData: function (data) {
      heatmapData = data
      render()
    }
  }
}

export default {
  props: {
    region: { type: Object },
    autospin: { type: Boolean, default: false },
    showGraticule: { type: Boolean, default: false },
    heatmapData: { type: Object, default: () => ({}) }
  },
  async mounted() {
    const world = await d3.json("/data/countries-50m-simplified-0.5.json")

    const canvas = this.$refs.globe
    const globe = init(canvas, canvas.clientWidth, canvas.clientHeight, world, this.autospin, this.showGraticule)
    this.$watch(
      "region",
      (newV, oldV) => {
        const to = newV?.isoNumericId
        const from = oldV?.isoNumericId
        if (to && from) {
          //topojson key is string type for numeric id
          globe.animate(String(to).padStart(3, "0"), String(from).padStart(3, "0"))
        }
      },
      { immediate: true }
    )
    this.$watch(
      "heatmapData",
      (data) => {
        globe.setHeatmapData(data)
      },
      { immediate: true }
    )
  }
}
</script>
