import { createApp, h, watch } from "vue"
import "animate.css"
import log from "loglevel"
import PrimeVue from "primevue/config"
import { LicenseManager } from "ag-grid-enterprise"
import { LogLevel } from "@azure/msal-browser"
import { useMsal } from "@wbim/msal-compositional"
import "ag-grid-enterprise"

import theme from "./utils/theme"
import App from "./App.vue"
import APIRepository from "./utils/APIRepository.js"
import { registerVuetifyPlugin } from "./plugins/vuetify.js"
import router from "./router/index.js"
import SovereignUniverse from "./utils/SovereignUniverse"

import "primeicons/primeicons.css"
import "@/assets/main.css"

LicenseManager.setLicenseKey(
  "CompanyName=William Blair & Company LLC,LicensedApplication=Summit,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=0,AssetReference=AG-026547,ExpiryDate=22_May_2023_[v2]_MTY4NDcxMDAwMDAwMA==466e1ba791fa4172958be3073ec2eec9"
)
// use a consistent window global object instead of vue's auto injection from dotfile,
// dotfile still setup envs on build time but injected to index.html to construct the object
const appConfig = window.appConfig
const clientId = appConfig.VITE_AzureAd__ClientId
const state = useMsal({ auth: { clientId }, system: { loggerOptions: { logLevel: LogLevel.Warning } } })
const msalApp = state.instance

async function initApp() {
  const app = createApp({ render: () => h(App) })
  registerVuetifyPlugin(app)

  const apiRepo = new APIRepository(appConfig)
  const sovereignUniverse = await SovereignUniverse.load(apiRepo)

  app
    .use(router)
    .use(PrimeVue, { theme: "none" })
    .provide("theme", theme)
    .provide("log", log)
    .provide("msalApp", msalApp)
    .provide("appConfig", appConfig)
    .provide("apiRepo", apiRepo)
    .provide("sovereignUniverse", sovereignUniverse)
    .mount("#app")
}

watch(
  () => state.isAuthenticated.value,
  async (msalReady) => {
    if (!msalReady) return
    initApp()
  }
)
