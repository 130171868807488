<script setup lang="ts">
import { useRegisterSW } from "virtual:pwa-register/vue"

// replaced dyanmicaly
const reloadSW = true

const { offlineReady, needRefresh, updateServiceWorker } = useRegisterSW({
  immediate: true,
  onRegistered(r: ServiceWorkerRegistration | undefined) {
    if (reloadSW && r) {
      setInterval(async () => {
        console.log("Checking for sw update")
        await r.update()
      }, 20000 /* 20s for testing purposes */)
    } else {
      console.log(`SW Registered: ${r}`)
    }
  }
})

const close = async () => {
  offlineReady.value = false
  needRefresh.value = false
}
</script>

<template>
  <div v-if="offlineReady || needRefresh" class="pwa-toast" role="alert">
    <div class="message">
      <span v-if="offlineReady">App ready to work offline</span>
      <span v-else>New content available, click on reload button to update.</span>
    </div>
    <button v-if="needRefresh" class="reload" @click="updateServiceWorker()">Reload</button>
    <button @click="close">Close</button>
  </div>
</template>

<style>
.pwa-toast {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px;
  border: 1px solid #8885;
  border-radius: 5px;
  z-index: 1000;
  text-align: left;
  box-shadow: 3px 4px 3px 0px #8885;
}
html.dark .pwa-toast {
  background: #000f;
  color: #fff;
}
html.light .pwa-toast {
  background: #ffff;
  color: #000;
}
.pwa-toast .message {
  margin-bottom: 8px;
}
.pwa-toast .reload {
  background: #329ef4;
  margin-right: 5px;
}
.pwa-toast button {
  border: 1px solid #8885;
  outline: none;
  margin-right: 5px;
  border-radius: 2px;
  padding: 3px 10px;
}
</style>
