<template>
  <div v-if="infoLoaded">
    <div v-show="initiated">
      <div>
        <CorpOpportunitySet
          v-if="maximized"
          :maximized="maximized"
          :issuer="issuer"
          @toggle-maximized="maximized = !maximized"
        ></CorpOpportunitySet>
      </div>
      <div v-show="!maximized" class="section-grid">
        <CorpIssuerDesc :issuer="issuer"></CorpIssuerDesc>
        <CorpCreditRiskModel :issuer="issuer"></CorpCreditRiskModel>
        <CorpESGSummary :issuer="issuer"></CorpESGSummary>
        <CorpOpportunitySet
          v-if="!maximized"
          :maximized="maximized"
          :issuer="issuer"
          @toggle-maximized="maximized = !maximized"
        ></CorpOpportunitySet>
        <CorpHistoricalSpread :issuer="issuer"></CorpHistoricalSpread>
        <CorpCreditUpdate :issuer="issuer"></CorpCreditUpdate>
      </div>
    </div>
    <div v-if="!initiated">
      <CorpCreditUpdate style="max-width: 800px" :expanded-view="true" :issuer="issuer"></CorpCreditUpdate>
    </div>
  </div>
</template>

<script>
import CorpIssuerDesc from "@/components/CorpIssuerDescription.vue"
import CorpCreditRiskModel from "@/components/CorpCreditRiskModel.vue"
import CorpCreditUpdate from "@/components/CorpCreditUpdate.vue"
import CorpOpportunitySet from "@/components/CorpOpportunitySet.vue"
import CorpHistoricalSpread from "@/components/CorpHistoricalSpread.vue"
import CorpESGSummary from "@/components/CorpESGSummary.vue"
import { loadIssuerInfo } from "@/apis/corp.js"

export default {
  components: {
    CorpIssuerDesc,
    CorpCreditRiskModel,
    CorpCreditUpdate,
    CorpOpportunitySet,
    CorpHistoricalSpread,
    CorpESGSummary
  },
  inject: ["apiRepo"],
  props: {
    issuer: { type: String, required: true },
    showExtended: { type: Boolean, default: false }
  },
  data() {
    return {
      maximized: false,
      infoLoaded: false,
      initiated: false
    }
  },
  async mounted() {
    const info = await loadIssuerInfo(this.apiRepo, this.issuer)
    this.initiated = info.model_initiated
    this.infoLoaded = true
  }
}
</script>

<style scoped>
.section-grid {
  display: grid;
  gap: 0px 16px;
  grid-template-columns: repeat(auto-fit, minmax(480px, 1fr));
}
</style>
