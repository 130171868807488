<template>
  <div class="impact-indicator" :style="{ height: height + 'px' }">
    <div class="impact impact-low"></div>
    <div class="impact impact-medium"></div>
    <div class="impact impact-high"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: "medium"
    },
    height: {
      type: Number,
      default: 20
    },
    activeColor: {
      type: String,
      default: "#2196f3"
    }
  },

  mounted() {
    const sel = this.value.toLowerCase()
    const alpha = sel === "low" ? "33" : sel === "medium" ? "88" : "ff"
    const activeEl = this.$el.querySelector(`.impact-${sel}`)
    activeEl.style.backgroundColor = this.activeColor + alpha
  }
}
</script>

<style>
.impact-indicator {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  box-sizing: border-box;

  .impact {
    flex: 1 1 100%;
    border: 1px solid #ccc;
  }

  .impact-low {
    border-top-left-radius: 100000000000000px;
    border-bottom-left-radius: 100000000000000px;
  }
  .impact-medium {
    border: 1px solid #ccc;
    border-left-width: 0;
    border-right-width: 0;
  }
  .impact-high {
    border-top-right-radius: 100000000000000px;
    border-bottom-right-radius: 100000000000000px;
  }
}
</style>
