<template>
  <div class="econ-esg">
    <div class="sidebar">
      <div v-if="currentViewIndex < 2" class="sidebar-item view-type">
        <label>Category</label>
        <div v-for="view in pillarOptions" :key="view.value" class="radio-item">
          <RadioButton v-model="pillar" :input-id="`view-type-${view.value}`" :value="view.value" :name="view.value" />
          <label :for="`view-type-${view.value}`" class="ml-2">{{ view.label }}</label>
        </div>
      </div>

      <div class="sidebar-item selected-year">
        <label>Present Year</label>
        <br />
        <Select v-model="selectedYear" :options="yearOptions" name="selectedYear" class="filter-dropdown" />
      </div>
      <div class="sidebar-item change-duration">
        <label>Change Duration</label>
        <br />
        <Select
          size="small"
          v-model="changeDuration"
          :options="changeDurationOptions"
          option-label="label"
          option-value="value"
          name="changeDuration"
          class="filter-dropdown"
        />
      </div>

      <div class="sidebar-item scale-type">
        <label>Scale Type</label>
        <div v-for="type in scaleTypeOptions" :key="type.value" class="radio-item">
          <RadioButton
            v-model="scoreType"
            :input-id="`scale-type-${type.value}`"
            :value="type.value"
            :name="type.value"
          />
          <label :for="`scale-type-${type.value}`" class="ml-2">{{ type.label }}</label>
        </div>
      </div>
    </div>
    <div class="main-content">
      <Tabs v-model:value="currentViewIndex">
        <TabList>
          <Tab :value="0">Peer Analysis</Tab>
          <Tab :value="1">Detail Analysis</Tab>
          <Tab :value="2">Pillar Analysis</Tab>
        </TabList>
        <TabPanels>
          <TabPanel :value="0">
            <div v-if="currentViewIndex === 0" class="peer-analysis">
              <div class="top-bottom">
                <Chart :options="topChartOptions"></Chart>
                <Chart :options="bottomChartOptions"></Chart>
              </div>
              <div class="low-bucket">
                <Chart :options="bucketOptions.Low"></Chart>
              </div>
              <div class="medium-bucket">
                <Chart :options="bucketOptions.Medium"></Chart>
              </div>
              <div class="high-bucket">
                <Chart :options="bucketOptions.High"></Chart>
              </div>
            </div>
          </TabPanel>
          <TabPanel :value="1">
            <div v-if="currentViewIndex === 1" class="detail-analysis">
              <div class="country-summary">
                <Card v-for="entry in factorEntries.filter((d) => d.Level === effectiveTopLevel)" :key="entry.Factor">
                  <template #title>
                    <div class="country-summary-entry-title">
                      {{ removeScoreFromLabel(entry.Factor) }} - {{ selectedYear }}
                    </div>
                  </template>
                  <template #content>
                    <div class="nested-cards">
                      <div class="nested-card-item">
                        Change since {{ selectedYear - changeDuration }}:
                        <div style="display: flex; align-items: center">
                          <IconMinusCircle
                            v-if="Math.abs(entry.observedChange) < 0.01"
                            style="color: gray; font-size: 2em"
                          />
                          <IconArrowCircleUp
                            v-else-if="entry.observedChange > 0"
                            style="color: green; font-size: 2em"
                          />
                          <IconArrowCircleDown v-else style="color: red; font-size: 2em" />
                          <span class="factor-change-label">
                            {{ fmtNum(entry.observedChange, 2) }}
                          </span>
                        </div>
                      </div>
                      <div class="nested-card-item">
                        Score:
                        <br />
                        <span class="factor-score-label">
                          {{ fmtNum(entry.Score, 2) }}
                        </span>
                      </div>
                      <div style="grid-column: 1 / 3">
                        <div class="sparkline-title">
                          5 Years History({{ `${selectedYear - 4} -> ${selectedYear}` }}):
                        </div>
                        <SparkLine :data="entry.last5PeriodsData" :height="96" />
                      </div>
                    </div>
                  </template>
                </Card>
              </div>
              <div class="factor-summary">
                <Chart :options="factorSnapshotOptions"></Chart>
                <Chart v-if="pillar === 'EPI' || pillar === 'SPI'" :options="factorSnapshotSubFactorOptions"></Chart>
              </div>
            </div>
          </TabPanel>
          <TabPanel :value="2">
            <div v-if="currentViewIndex === 2" class="pillar-analysis">
              <div class="pillar-analysis-section">
                <div class="pillar-analysis-title">ESG Overview</div>
                <div class="pillar-analysis-charts">
                  <Chart :options="pillarESG.current"></Chart>
                  <Chart :options="pillarESG.previous"></Chart>
                  <Chart :options="pillarESG.change"></Chart>
                </div>
              </div>
              <div class="pillar-analysis-section">
                <div class="pillar-analysis-title">
                  Environmental
                  <div class="title-control">
                    <Checkbox v-model="showEPILevel2" input-id="checkboxShowEPILevel2" :binary="true" />
                    <label for="checkboxShowEPILevel2" class="ml-2">Show L2</label>
                  </div>
                </div>
                <div class="pillar-analysis-charts">
                  <Chart :options="pillarEnvironmental.current"></Chart>
                  <Chart :options="pillarEnvironmental.previous"></Chart>
                  <Chart :options="pillarEnvironmental.change"></Chart>
                </div>
              </div>
              <div class="pillar-analysis-section">
                <div class="pillar-analysis-title">
                  Social
                  <div class="title-control">
                    <Checkbox v-model="showSPILevel2" input-id="checkboxShowSPILevel2" :binary="true" />
                    <label for="checkboxShowSPILevel2" class="ml-2">Show L2</label>
                  </div>
                </div>
                <div class="pillar-analysis-charts">
                  <Chart :options="pillarSocial.current"></Chart>
                  <Chart :options="pillarSocial.previous"></Chart>
                  <Chart :options="pillarSocial.change"></Chart>
                </div>
              </div>
              <div class="pillar-analysis-section">
                <div class="pillar-analysis-title">Governance</div>
                <div class="pillar-analysis-charts">
                  <Chart :options="pillarGovernance.current"></Chart>
                  <Chart :options="pillarGovernance.previous"></Chart>
                  <Chart :options="pillarGovernance.change"></Chart>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch, inject } from "vue"
import Chart, { type Options, type Highcharts } from "@wbim/highcharts-themed"
import { useRouter } from "vue-router"
import Card from "primevue/card"
import Tabs from "primevue/tabs"
import TabList from "primevue/tablist"
import Tab from "primevue/tab"
import TabPanels from "primevue/tabpanels"
import TabPanel from "primevue/tabpanel"
import RadioButton from "primevue/radiobutton"
import Checkbox from "primevue/checkbox"
import Select from "primevue/select"
import { IconMinusCircle, IconArrowCircleUp, IconArrowCircleDown } from "@wbim/ui-icons"

import SparkLine from "@/components/common/SparkLine.vue"
import { fmtNum } from "@/utils/vmMethods"
import {
  changeDurationOptions,
  pillarOptions,
  scaleTypeOptions,
  getMostRecentYear,
  getLeaderLaggerData,
  getCountryDetailWithHistoryAndChangeObservation as getCountryDetailWithHistory,
  getBetaBucketAnalysisResult,
  getCountryPillarAnalysis,
  initApiClient
} from "@/apis/esg"
import type {
  ESGDataEntry,
  Pillar,
  ScoreType,
  ChangeDuration,
  CountryDetailWithHistoryAndChangeObservationResult,
  BetaBucketAnalysisResults,
  LeaderLaggerDataResult
} from "@/apis/esg"
import type SovereignUniverse from "@/utils/SovereignUniverse"
import type APIRepository from "@/utils/APIRepository"

const props = defineProps({
  region: {
    type: String,
    required: true
  }
})
const router = useRouter()

const sovereignUniverse: SovereignUniverse = inject("sovereignUniverse")!

const apiRepo: APIRepository = inject("apiRepo")!

const removeScoreFromLabel = (str: string) => str.replace("Score", "")
//TabView component does not trigger a rerender of highcharts which is causing dimention issue
//use a v-if on the content to work around
const currentViewIndex = ref(0)
const selectedYear = ref(2022)
const pillar = ref<Pillar>("Overview")
const scoreType = ref<ScoreType>("scaled")
const changeDuration = ref<ChangeDuration>(3)
const showEPILevel2 = ref(false)
const showSPILevel2 = ref(false)
const leaderLaggerData = ref<LeaderLaggerDataResult>({ leader: [], lagger: [] })
const betaBucketAnalysisResult = ref<BetaBucketAnalysisResults>({ Low: [], Medium: [], High: [] })
const mostRecentYear = ref(2022) //static value and should not be changed after data loaded
const factorEntries = ref<CountryDetailWithHistoryAndChangeObservationResult>([])
const countryDetailAnalysisResult = ref<ESGDataEntry[]>([])

const countryCode3 = computed(() => sovereignUniverse.code2To3(props.region))
const compareYear = computed(() => selectedYear.value - changeDuration.value)
const yearOptions = computed(() => new Array(3).fill(0).map((_, i) => mostRecentYear.value - i))
const effectiveTopLevel = computed(() => (pillar.value === "Overview" ? 0 : 1))
const effectiveFactor = computed(() => {
  switch (pillar.value) {
    case "EPI":
      return "EnvironmentalScore"
    case "SPI":
      return "SocialScore"
    case "Governance":
      return "GovernanceScore"
    default:
      return "ESGScore"
  }
})

onMounted(async () => {
  await initApiClient(apiRepo)
  mostRecentYear.value = await getMostRecentYear()
  selectedYear.value = mostRecentYear.value

  watch(
    [pillar, selectedYear, changeDuration, scoreType],
    async () => {
      const filtersWithoutPillar = [selectedYear.value, changeDuration.value, scoreType.value] as const
      const filtersWithPillar = [pillar.value, ...filtersWithoutPillar] as const

      leaderLaggerData.value = await getLeaderLaggerData(...filtersWithPillar)
      betaBucketAnalysisResult.value = await getBetaBucketAnalysisResult(...filtersWithPillar)
      factorEntries.value = await getCountryDetailWithHistory(countryCode3.value, ...filtersWithPillar)
      countryDetailAnalysisResult.value = await getCountryPillarAnalysis(countryCode3.value, ...filtersWithoutPillar)
    },
    { immediate: true }
  )
})

const getYAxisMinMax = (data: number[]) => {
  if (scoreType.value === "scaled") {
    return { min: 0, max: 100 }
  }

  const sorted = data.sort((a, b) => a - b)
  const stdMin = sorted[0]
  const stdMax = sorted[sorted.length - 1]
  const normalized = Math.max(Math.abs(stdMin), Math.abs(stdMax))
  return { min: -normalized, max: normalized }
}

const factorSnapshotOptions = computed<Options>(() => {
  const data = factorEntries.value.filter((d) => d.Level === effectiveTopLevel.value)
  const mean = data.reduce((acc, d) => acc + d.Score, 0) / data.length
  return {
    chart: { type: "column", margin: [32, 32, 64, 32] },
    title: { text: "Factor Summary " + selectedYear.value },
    xAxis: { type: "category" },
    yAxis: {
      title: { text: "Score" },
      ...getYAxisMinMax(data.map((d) => d.Score)),
      plotLines: [
        {
          color: "#e1c233",
          width: 2,
          value: mean,
          zIndex: 5
        }
      ]
    },
    plotOptions: {
      column: {
        colorByPoint: true
      }
    },
    tooltip: { valueDecimals: 2 },
    series: [{ name: "Score", type: "column", data: data.map((d) => ({ name: d.Factor, y: d.Score })) }]
  }
})

const factorSnapshotSubFactorOptions = computed<Options>(() => {
  const data = factorEntries.value.filter((d) => d.Level === effectiveTopLevel.value + 1)
  const mean = data.reduce((acc, d) => acc + d.Score, 0) / data.length
  return {
    chart: { type: "column", margin: [32, 32, 64, 32] },
    title: { text: "Sub Factor Summary " + selectedYear.value },
    xAxis: { type: "category" },
    yAxis: {
      title: { text: "Score" },
      ...getYAxisMinMax(data.map((d) => d.Score)),
      plotLines: [
        {
          color: "#e1c233",
          width: 2,
          value: mean,
          zIndex: 5
        }
      ]
    },
    tooltip: { valueDecimals: 2 },
    series: [{ name: "Score", type: "column", data: data.map((d) => ({ name: d.Factor, y: d.Score })) }]
  }
})

const topChartOptions = computed<Options>(() => ({
  chart: { type: "bar", margin: [32, 32, 32, 128] },
  title: { text: `Top Improving Countries (${effectiveFactor.value.replace("Score", "")})` },
  xAxis: { type: "category" },
  yAxis: { title: { text: "Change" } },
  tooltip: { valueDecimals: 2 },
  colors: ["#529777"],
  plotOptions: {
    series: {
      borderColor: "#fff0"
    }
  },
  series: [
    {
      name: "Change",
      type: "bar",
      data: leaderLaggerData.value.leader.map((d) => ({ name: d.EMDRiskCountry, y: d.delta }))
    }
  ]
}))

const bottomChartOptions = computed<Options>(() => ({
  chart: { type: "bar", margin: [32, 32, 32, 128] },
  title: { text: `Bottom Deteriorating Countries  (${effectiveFactor.value.replace("Score", "")})` },
  xAxis: { type: "category" },
  yAxis: { title: { text: "Change" } },
  tooltip: { valueDecimals: 2 },
  colors: ["#fa9884"],
  plotOptions: {
    series: {
      borderColor: "#fff0"
    }
  },
  series: [
    {
      name: "Change",
      type: "bar",
      data: leaderLaggerData.value.lagger.map((d) => ({ name: d.EMDRiskCountry, y: d.delta }))
    }
  ]
}))

interface BucketPoint extends Highcharts.Point {
  countryCode: string
}

const getBucketAnalysisHighchartsConfig = (data: ESGDataEntry[], titleText: string) => {
  const mean = data.reduce<number>((acc, item) => acc + item.Score, 0) / data.length
  const getBarColor = (item: ESGDataEntry) => {
    const delta = item.Score - item[`Score${changeDuration.value}YearAgo`]
    if (Math.abs(delta) < 0.5 * changeDuration.value) {
      return "#393939"
    } else if (delta < 0) {
      return "#fa9884"
    } else {
      return "#529777"
    }
  }
  const currentCountryIndex = data.findIndex((item) => item.CountryCode === countryCode3.value)
  const xAxisFormatter: Highcharts.AxisLabelsFormatterCallbackFunction = function () {
    if (this.value === data[currentCountryIndex]?.EMDRiskCountry) {
      return `<div style="border: 2px solid #56afef; border-radius: 4px; font-size: 14px; padding: 4px">${this.value}</div>`
    } else {
      return String(this.value)
    }
  }

  const options: Options = {
    chart: { type: "column", margin: [32, 32, 96, 32] },
    title: { text: titleText },
    xAxis: {
      type: "category",
      categories: data.map((item) => item.EMDRiskCountry),
      crosshair: true,
      labels: {
        useHTML: true,
        formatter: xAxisFormatter
      }
    },
    yAxis: {
      ...getYAxisMinMax(data.map((item) => item.Score)),
      plotLines: [
        {
          color: "#e1c233",
          width: 2,
          value: mean,
          zIndex: 5
        }
      ]
    },

    tooltip: { valueDecimals: 2, shared: true },

    plotOptions: {
      column: {
        cursor: "pointer",
        events: {
          click(event) {
            const point = event.point as BucketPoint
            const countryCode3 = point.countryCode
            router.push({
              name: "econ-sustainability",
              params: { region: sovereignUniverse.code3To2(countryCode3) }
            })
          }
        },
        colorByPoint: true,
        borderColor: "#fff0"
      }
    },

    colors: data.map(getBarColor),

    series: [
      {
        name: String(selectedYear.value - changeDuration.value),
        type: "column",
        data: data.map((d) => ({
          name: d.EMDRiskCountry,
          countryCode: d.CountryCode,
          y: d[`Score${changeDuration.value}YearAgo`]
        }))
      },
      {
        name: String(selectedYear.value),
        type: "column",
        data: data.map((d) => ({ name: d.EMDRiskCountry, countryCode: d.CountryCode, y: d.Score }))
      }
    ]
  }
  return options
}

const bucketOptions = computed(() => {
  const { Low, Medium, High } = betaBucketAnalysisResult.value
  const yearRange = `(${compareYear.value} v.s. ${selectedYear.value})`
  return {
    Low: getBucketAnalysisHighchartsConfig(Low, `Low Bucket ${yearRange}`),
    Medium: getBucketAnalysisHighchartsConfig(Medium, `Medium Bucket ${yearRange}`),
    High: getBucketAnalysisHighchartsConfig(High, `High Bucket ${yearRange}`)
  }
})

const getRadarChartConfig = (data: Highcharts.PointOptionsObject[], titleText: string | number): Highcharts.Options => {
  const series: Highcharts.SeriesAreaOptions[] = [
    {
      type: "area",
      data,
      pointPlacement: "on"
    }
  ]
  return {
    chart: { polar: true },
    title: { text: String(titleText) },
    xAxis: {
      type: "category",
      tickmarkPlacement: "on",
      lineWidth: 0
    },

    yAxis: {
      gridLineInterpolation: "polygon",
      tickmarkPlacement: "between",
      labels: {
        enabled: false
      }
    },

    tooltip: { enabled: false },

    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          format: "{point.y:.1f}"
        }
      }
    },
    series
  }
}

const getComputedRadarChartConfigs = (data: ESGDataEntry[], changeDuration: ChangeDuration) => {
  const arr = data.map((d) => ({
    name: removeScoreFromLabel(d.Factor),
    score: d.Score,
    compare: d[`Score${changeDuration}YearAgo`],
    change: d.Score - d[`Score${changeDuration}YearAgo`]
  }))
  const current = getRadarChartConfig(
    arr.map((d) => ({ name: d.name, y: d.score })),
    selectedYear.value
  )
  const previous = getRadarChartConfig(
    arr.map((d) => ({ name: d.name, y: d.compare })),
    compareYear.value
  )
  const change = getRadarChartConfig(
    arr.map((d) => ({ name: d.name, y: d.change })),
    "Changes"
  )
  return { current, previous, change }
}

const esgOverviewData = computed(() =>
  countryDetailAnalysisResult.value.filter((d) => d.Pillar === "Overview" && d.Factor !== "ESGScore")
)
const environmentalData = computed(() =>
  countryDetailAnalysisResult.value.filter(
    (d) =>
      d.Pillar === "EPI" && d.Level === (showEPILevel2.value ? 2 : 1) && d.Factor !== "Environmental Progress Index"
  )
)
const socialData = computed(() =>
  countryDetailAnalysisResult.value.filter(
    (d) => d.Pillar === "SPI" && d.Level === (showSPILevel2.value ? 2 : 1) && d.Factor !== "Social Progress Index"
  )
)
const governanceData = computed(() =>
  countryDetailAnalysisResult.value.filter((d) => d.Pillar === "Governance" && d.Level === 1)
)

const pillarESG = computed(() => getComputedRadarChartConfigs(esgOverviewData.value, changeDuration.value))
const pillarEnvironmental = computed(() => getComputedRadarChartConfigs(environmentalData.value, changeDuration.value))
const pillarSocial = computed(() => getComputedRadarChartConfigs(socialData.value, changeDuration.value))
const pillarGovernance = computed(() => getComputedRadarChartConfigs(governanceData.value, changeDuration.value))
</script>

<style>
.econ-esg {
  display: grid;
  grid-template-columns: 320px 1fr;
  gap: 16px;
  height: 100%;
}
.sidebar-item {
  margin: 8px 0;
}

.main-content {
  display: grid;
}

.main-content .p-tabview-panels {
  padding: 8px 0;
}

.top-bottom {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 16px;
}

.filter-dropdown {
  height: 2.5em;
  width: 100%;
}

.filter-dropdown .p-dropdown-label {
  display: flex;
  align-items: center;
}

.current-country-highlight {
  border: 2px dotted green;
}
.peer-analysis,
.detail-analysis {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}
.country-summary {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
}
.country-summary .p-card {
  background-color: #212121 !important;
  border: 1px solid #3c3c3c !important;
}
.country-summary-entry-title {
  font-size: 16px;
  font-weight: 600;
  color: #c3c3c3;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nested-cards {
  font-size: 14px;
  margin: -8px 0;
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
}
.nested-card-item {
  flex: 1;
  background-color: #3c3c3c;
  padding: 16px;
  border-radius: 4px;
}
.factor-summary {
  display: grid;
  grid-template-columns: 1fr;
  gap: 16px;
}
.factor-score-label {
  font-size: 28px;
}
.radio-item {
  display: flex;
  align-items: center;
  margin: 8px 0;
}
.radio-item label {
  cursor: pointer;
}
.pillar-analysis-title {
  display: flex;
  height: 32px;
  font-size: 18px;
  align-items: center;
}
.pillar-analysis-title .title-control {
  margin-left: 16px;
  display: flex;
  align-items: center;
}
.pillar-analysis-charts {
  display: grid;
  gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
}

html.light li .p-tabview-nav-link {
  color: #121212 !important;
}
</style>
