<template>
  <div :class="{ 'input-canonical': !isEditing }" style="flex: 1">
    <div v-if="!message" class="d-inline pl-4">
      <ColorScaledScore v-if="!isEditing" :score="value" />
      <input
        v-else
        ref="input"
        pattern="[0-9][0-9]?|100"
        class="subtle-input number-input__no-arrow"
        style="width: 36px; text-align: center"
        :value="value"
        :disabled="!isEditing"
        @input="check"
      />

      <v-btn v-if="!isEditing" size="x-small" variant="text" color="primary" @click="switchToEdit">Edit</v-btn>

      <template v-else>
        <v-btn size="x-small" variant="text" color="warning" class="mr-2" @click="discard">Discard</v-btn>
        <v-btn size="x-small" variant="outlined" color="primary" @click="save">Save</v-btn>
      </template>
    </div>

    <v-alert v-else class="alert-inset mx-2 mb-0" text dense :type="alertType">
      <span style="font-size: 12px">
        {{ message }}
      </span>
    </v-alert>
  </div>
</template>

<script>
import ColorScaledScore from "./ColorScaledScore.vue"

import EventBus from "@/utils/eventbus.js"

export default {
  components: { ColorScaledScore },
  inject: ["apiRepo"],
  props: {
    issuer: { type: String, required: true },
    dbkey: { type: String, required: true }
  },
  data() {
    return {
      isEditing: false,
      value: 0,
      alertType: "info",
      message: ""
    }
  },

  async mounted() {
    const api = await this.apiRepo.makeServiceAPIClient()
    const { status, data } = await api.get(`/api/corp_crm_models/${this.issuer}?path=${this.dbkey}`)
    if (status === 200) {
      this.value = data
    }
  },

  methods: {
    // ...mapMutations(["setState"]),
    check(e) {
      const el = e.currentTarget
      if (!el.validity.valid) {
        el.value = this.value
      }
    },
    switchToEdit() {
      this.isEditing = true
    },

    discard() {
      this.isEditing = false
    },

    async save() {
      //need to use the native way to get the updated value
      const updatedVal = this.$refs.input.value

      this.value = Number(updatedVal)

      if (updatedVal !== this.localValue) {
        const api = await this.apiRepo.makeServiceAPIClient()
        const { status } = await api.patch(`/api/corp_crm_models/${this.issuer}`, {
          path: this.dbkey,
          value: this.value,
          update_crm_date: true
        })

        if (status === 200) {
          this.message = "Field value updated successfully"
          this.alertType = "success"
          setTimeout(() => (this.message = ""), 2000)
          EventBus.$emit("corp-risk-model-updated")
        } else {
          this.message = "Update field failed"
          this.alertType = "error"
          setTimeout(() => (this.message = ""), 2000)
        }
        this.isEditing = false
      }
    }
  }
}
</script>

<style>
.input-canonical .subtle-input {
  border: none;
}

.number-input__no-arrow {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type="number"] {
    appearance: textfield;
    -moz-appearance: textfield;
  }
}

.alert-inset {
  padding: 0;
  font-size: 12px;
  .v-icon {
    font-size: 16px;
  }
}
</style>
