<template>
  <div ref="events" class="events">
    <div class="section-header">
      <h3>Events</h3>
    </div>
  </div>
  <BasicTable :rows="rows" :widths="widths"></BasicTable>
</template>

<script setup lang="ts">
import { BasicTable } from "@wbim/ui-base-components"
import { parse, addDays, startOfToday } from "date-fns"
import { onMounted, inject, ref } from "vue"

import type SovereignUniverse from "@/utils/SovereignUniverse"
import type APIRepository from "@/utils/APIRepository"

const apiRepo = inject<APIRepository>("apiRepo")!
const sovereignUniverse = inject<SovereignUniverse>("sovereignUniverse")!

const props = defineProps<{ region?: string }>()

const rows = ref<EventRow[]>([])

interface EventRawRow {
  "Date Time": string
  "Country/Region": string
  Event: string
  Period: string
  "Surv(M)": string
  Actual: string
  Prior: string
  Revised: string
}

interface EventRow {
  dateTime: string
  eventName: string
  survey: string
  prior: string
  region: string
}

function getName(code: string) {
  return sovereignUniverse.fromAlpha2(code)?.name
}

onMounted(async () => {
  const events = (await apiRepo.requestBlobCSV("econ-releases/econ-releases.csv")) as EventRawRow[]

  rows.value = events
    .filter((d) => d["Date Time"] && d["Event"])
    .filter((d) => (!props.region ? true : d["Country/Region"] === getName(props.region)))
    .map((d) => {
      const date = d["Date Time"]
      //include time, convert to local timezone
      const start = date.includes(":")
        ? parse(date + "-0500", "MM/dd/yyyy H:mmxx", new Date())
        : parse(date.split(/[-]/)[0], "MM/dd/yyyy", new Date())
      //when offered a from-to range
      const end = date.includes("-") ? parse(date.split(/[-]/)[1], "MM/dd/yyyy", new Date()) : start
      return { ...d, start, end, name: d["Event"], regionName: d["Country/Region"] }
    })
    .filter((d) => d.end >= startOfToday() && d.end <= addDays(startOfToday(), 1))
    .sort((a, b) => a.start.valueOf() - b.start.valueOf())
    .map((event) => ({
      dateTime: event["Date Time"],
      region: event["Country/Region"],
      eventName: (event.Period ? `[${event.Period}] ` : "") + event.Event,
      survey: event["Surv(M)"],
      prior: event.Prior
    }))
})

const widths = { dateTime: 150, region: 100, survey: 70, prior: 70 }
</script>

<style>
.events {
  position: relative;
  width: auto;
}
.events table {
  table-layout: fixed;
}
</style>
