<template>
  <div>
    <div class="section-header">
      <h3>Positions</h3>
    </div>
    <div class="section-body d-flex flex-wrap ma-n1">
      <div class="ma-1" style="flex: 1 0 240px">
        <PositionCard card-title="Local Currency" :items="localCurrencyItem"></PositionCard>
      </div>
      <div class="ma-1" style="flex: 1 0 240px">
        <PositionCard
          card-title="Hard Currency"
          :to="`/countries/${region}/econ`"
          :items="hardCurrencyItem"
        ></PositionCard>
      </div>
      <div class="ma-1" style="flex: 1">
        <PositionCardEquity
          :to="`/countries/${region}/equity`"
          :items="equityItem"
          @show-dialog="showEquityPositionDialog = true"
        ></PositionCardEquity>
      </div>
    </div>
  </div>
</template>

<script>
import PositionCard from "@/components/CountrySummaryPositionCard.vue"
import PositionCardEquity from "@/components/CountrySummaryPositionCardEquity.vue"
import { fmtNum } from "@/utils/vmMethods.js"

export default {
  components: { PositionCard, PositionCardEquity },
  inject: ["log", "apiRepo", "sovereignUniverse"],

  props: {
    region: { type: String, required: true }
  },

  data() {
    return {
      showEquityPositionDialog: false,
      localCurrencyItem: {},
      hardCurrencyItem: {},
      equityItem: []
    }
  },

  async mounted() {
    const rowsHC = await this.apiRepo.requestBlobCSV("enfusion-reports/Position_HC.csv")
    const rowsLC = await this.apiRepo.requestBlobCSV("enfusion-reports/Position_FX_LC.csv")
    const rowsEq = await this.loadEquityPositions()

    this.$watch(
      "region",
      () => {
        const hcRecord = rowsHC.find((d) => d.EMDISOCode === this.alpha2ToAlpha3(this.region)) ?? {}
        const lcRecord = rowsLC.find((d) => d.EMDRiskCountry === this.getName(this.region)) ?? {}

        this.hardCurrencyItem = {
          "Active Spread Duration": this.fmtNum(hcRecord["Active Duration Bet Yrs"], 3),
          "Active Cash":
            hcRecord["Active MV Bet"] !== undefined ? this.fmtNum(100 * hcRecord["Active MV Bet"], 2) + "%" : "-"
        }

        this.localCurrencyItem = {
          "Active Duration": this.fmtNum(lcRecord["Active Duration Bet Yrs"], 3),
          "Active FX Exposure":
            lcRecord["EMDActiveLocalFXBet"] !== undefined
              ? this.fmtNum(100 * lcRecord["EMDActiveLocalFXBet"], 2) + "%"
              : "-"
        }

        this.equityItem = rowsEq
          .filter((d) => d.country_code === this.region)
          .map((d) => [d.portfolio_name, d.country_value, d.country_pct])
          .sort((a, b) => (a.portfolio_name > b.portfolio_name ? -1 : a.portfolio_name < b.portfolio_name ? 1 : 0))
      },
      { immediate: true }
    )
  },
  methods: {
    fmtNum,
    alpha2ToAlpha3(code2) {
      return this.sovereignUniverse.code2To3(code2)
    },
    getName(code2) {
      return this.sovereignUniverse.fromAlpha2(code2)?.name ?? ""
    },
    async loadEquityPositions() {
      const api = await this.apiRepo.makeServiceAPIClient()
      const { data, status } = await api.get("/api/BlobProxy?path=/equity-country-exposure/latest.json")
      if (status === 200 && Array.isArray(data)) {
        return data
      } else {
        this.log.error("unable to load country exposure file")
        return []
      }
    }
  }
}
</script>

<style>
.group-header {
  font-size: 12px;
  padding: 2px;
  background: #d1d1d1;
  border: 1px solid #999;
  font-weight: 600;
  color: #666;
  overflow: hidden;
}

.position-header {
  height: 32px;
  line-height: 32px;
  display: flex;
  border-bottom: 1px solid #ccc;

  font-size: 12px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}
.position-item {
  height: 32px;
  line-height: 32px;
  display: flex;
}
.position-card-wrap {
  flex: 1 0 220px;
  align-items: stretch;
  color: #c1c1c1;
}
</style>
