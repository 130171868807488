import { formatDistanceToNow, parse } from "date-fns"

export function tryFormatDate(dateStr, format = "yyyy-MM-dd'T'HH:mm:ss'Z'") {
  try {
    const tidyDateStr = dateStr.replace(/\.\d{0,}Z/, "Z")
    const date = parse(tidyDateStr, format, new Date())
    return date instanceof Date ? formatDistanceToNow(date) : "Invalid Date"
  } catch {
    return "Invalid Date"
  }
}
