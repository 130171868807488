<template>
  <div class="user-editor ql-container" :class="{ editing: isEditing, readonly: !isEditing }">
    <div class="thesis-section-header d-flex">
      <h4 v-if="title" class="content-title mr-4">{{ title }}</h4>
      <div v-if="!isEditing && author && showByline">
        <span v-if="author.name" class="doc-author">By {{ author.name }}</span>
        <span v-if="author.date" class="doc-date">&nbsp;Updated {{ fmtDate(author.date, "MMM dd, yyyy HH:mm") }}</span>
      </div>
      <v-spacer></v-spacer>
      <button
        v-if="!isEditing"
        class="text-button doc-edit-start-btn"
        size="x-small"
        variant="text"
        color="primary"
        @click="isEditing = true"
      >
        Edit
      </button>
      <template v-else>
        <button class="text-button doc-edit-discard-btn" @click="discard">Discard</button>
        <button class="text-button outline-button doc-edit-save-btn" @click="save">Save</button>
      </template>
    </div>
    <div
      v-show="!isEditing"
      ref="editorReadOnly"
      class="content-readonly"
      style="height: calc(100% - 24px - 12px); overflow: auto; margin: 8px 0"
      @click="isEditing = !prevContent"
      @scroll="onScroll"
    ></div>
    <div v-show="isEditing" ref="editor" style="height: calc(100% - 24px - 26px); overflow: auto"></div>

    <div v-if="moreContentBelow" class="more-content-indicator" @click="manualScroll">
      <v-icon>mdi-chevron-down</v-icon>
    </div>
    <div v-if="snack" :style="{ backgroundColor: snackColor }" class="snack">{{ snackText }}</div>
  </div>
</template>

<script setup>
import { isEqual } from "lodash-es"
import { ref, watch, onMounted } from "vue"
import Quill from "quill"

import { fmtDate } from "@/utils/vmMethods.js"

const BackgroundClass = Quill.import("attributors/class/background")
const ColorClass = Quill.import("attributors/class/color")
Quill.register(BackgroundClass, true)
Quill.register(ColorClass, true)

const color = ["black", "red", "orange", "yellow", "green", "blue", "purple"]
const background = ["white", "red", "orange", "yellow", "green", "blue", "purple"]
const quillConfig = {
  modules: {
    history: {
      delay: 2000,
      maxStack: 50
    },
    toolbar: [
      [
        { header: [1, 2, 3, 4, false] },
        "bold",
        "italic",
        "underline",
        "strike",
        { align: ["", "center", "right", "justify"] }
      ],
      [{ indent: "-1" }, { indent: "+1" }, { list: "ordered" }, { list: "bullet" }],
      ["link", "blockquote", "code-block", "image", { background }, { color }, "clean"]
    ]
  },
  // placeholder: "start editing...",
  theme: "snow"
}
const defaultSnackProperties = {
  colors: {
    success: "#2c9e2b",
    error: "#ff0066"
  },
  messages: {
    success: "Change saved successfully",
    error: "Error occured during saving the changes"
  }
}

const editor = ref(null)
const editorReadOnly = ref(null)
const isEditing = ref(false)
const prevContent = ref(null)
const snack = ref(false)
const snackText = ref("")
const snackColor = ref("success")
const moreContentBelow = ref(false)

const props = defineProps({
  title: { type: String },
  showByline: { type: Boolean, default: true },
  tileView: { type: Boolean, default: false },
  content: { type: Object, default: () => ({ ops: [] }) },
  saveResult: { type: Object },
  author: { type: Object }
})

const emit = defineEmits(["save"])

let quill

onMounted(() => {
  watch(
    () => props.content,
    (content) => {
      if (!content) return
      prevContent.value = content
      if (!quill) {
        quill = new Quill(editor.value, quillConfig)
      }
      quill.setContents(content.ops)
      editorReadOnly.value.innerHTML = quill.root.innerHTML
      moreContentBelow.value = editorReadOnly.value.scrollHeight > editorReadOnly.value.clientHeight
    },
    { immediate: true, deep: true }
  )
})

function manualScroll() {
  editorReadOnly.value.scrollTop += 32
}

function onScroll() {
  // update moreContentBelow while scroll content toward bottom
  if (!props.tileView) return
  const { scrollTop, scrollHeight, clientHeight } = editorReadOnly.value
  moreContentBelow.value = scrollHeight - scrollTop - clientHeight > 10
}

function discard() {
  isEditing.value = false
  if (prevContent.value) {
    quill.setContents(prevContent.value.ops)
  }
}

function save() {
  isEditing.value = false
  const newContent = quill.getContents()
  if (isEqual(newContent, prevContent.value)) {
    this.isEditing = false
    return
  }
  emit("save", quill.getContents())
  editorReadOnly.value.innerHTML = quill.root.innerHTML
}

function showSnack(type = "success", msg) {
  const { colors, messages } = defaultSnackProperties
  snack.value = true
  snackText.value = msg ?? messages[type]
  snackColor.value = colors[type]
  setTimeout(() => {
    snack.value = false
  }, 40000)
}

defineExpose({
  showSnack
})
</script>

<style src="quill/dist/quill.snow.css"></style>
<style scoped>
.user-editor {
  .text-button {
    outline: none;
    font-size: 12px !important;
    line-height: 24px !important;
    padding: 0 6px;
    font-weight: 500;
    text-decoration: none;
    cursor: pointer;
  }

  .outline-button {
    border: 1px solid currentColor;
    border-radius: 4px;
  }

  .doc-edit-start-btn {
    color: #2196f3;
  }

  .doc-edit-discard-btn {
    color: #fca02d;
  }

  .doc-edit-save-btn {
    color: #0082c8;
  }

  position: relative;
  min-height: 100px;
  height: 100%;
}

.thesis-section-header {
  display: flex;
  align-items: center;
  h4 {
    font-size: 18px;
    font-weight: normal;
    vertical-align: center;
  }
  h4:before {
    content: "➤ ";
    font-size: 10px;
    position: relative;
    bottom: 3px;
    color: #2196f3;
  }
}
.editor-wrap {
  display: flex;
  flex-direction: column;

  .v-theme--dark & {
    color: #c1c1c1;
  }
  .v-theme--light & {
    color: #1c1c1c;
  }
}

.doc-author {
  font-size: 12px;

  .v-theme--dark & {
    color: #c3c3c3;
  }

  .v-theme--light & {
    color: #3c3c3c;
  }
}

.doc-date {
  color: #0077cf;
  font-size: 12px;
}

.readonly :deep(.ql-toolbar) {
  display: none;
}

.editing :deep(.ql-toolbar) {
  display: block;
}

:deep(.v-snack .v-snack__wrapper) {
  min-height: 32px;
  max-height: 32px;
  min-width: 160px;
  max-width: 360px;
  margin: 0;
}

.editor-wrap.editing .ql-container {
  max-height: 400px;
  overflow-y: auto;
}

.editor {
  padding: 0;
  min-height: 120px;
}

:deep(.editor img) {
  max-width: 100%;
}

.editor-wrap__content-card {
  box-sizing: border-box;
  position: absolute;
  top: 32px;
  left: 16px;
  bottom: 16px;
  right: 16px;
  overflow: auto;
}

.more-content-indicator {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  height: 32px;
  .v-theme--dark & {
    background: linear-gradient(0deg, #4c4c4c, rgba(20%, 0%, 0%, 0));
  }
  .v-theme--light & {
    background: linear-gradient(0deg, #fff, rgba(0, 0%, 100%, 0));
  }
  display: flex;
  align-items: end;
  justify-content: center;
}

.snack {
  position: absolute;
  min-width: 160px;
  right: 8px;
  bottom: 4px;
  text-align: right;
  z-index: 10;
  padding: 4px;
  border-radius: 4px;
}
</style>
