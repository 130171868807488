<template>
  <div>
    <div class="section-header">
      <h3>Opportunity Set</h3>
      <div class="section-header-right ml-sm-0 ml-md-auto">
        <input v-model="showAll" type="checkbox" />
        <label style="font-size: 10px">Show All</label>
        <v-btn-toggle v-model="viewType" class="mx-2" mandatory rounded density="compact">
          <v-btn size="x-small">By Country</v-btn>
          <v-btn size="x-small">By Credit Bucket</v-btn>
        </v-btn-toggle>
      </div>
      <v-btn icon size="x-small" @click="$emit('toggle-maximized')">
        <v-icon>{{ maximized ? "mdi-arrow-collapse" : "mdi-arrow-top-right-bottom-left" }}</v-icon>
      </v-btn>
    </div>

    <div
      v-if="loading"
      class="content-card"
      style="height: 360px; display: grid; align-items: center; justify-content: center"
    >
      <v-progress-circular :size="48" color="primary" indeterminate></v-progress-circular>
    </div>
    <highcharts v-else ref="corpOpportunitySet" :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { groupBy } from "lodash-es"
import Chart from "@wbim/highcharts-themed"

import filterOutliers from "@/utils/outliers.js"
import { loadIssuerInfo } from "@/apis/corp.js"
import { fmtNum } from "@/utils/vmMethods.js"

export default {
  components: {
    highcharts: Chart
  },
  inject: ["log", "apiRepo", "theme"],
  props: {
    issuer: { type: String, required: true },
    maximized: { type: Boolean, default: false }
  },
  emits: ["toggle-maximized"],
  data() {
    return {
      viewType: 0,
      updateDate: [],
      rawData: [],
      showAll: false,
      loading: true
    }
  },
  computed: {
    series() {
      const vm = this
      const by = vm.viewType === 0 ? "country" : "creditBucket"

      return Object.values(
        groupBy(
          vm.rawData
            .filter((d) => d.instrument && d.sub_credit_bucket && d.spread_duration >= 0)
            .map((d) => ({
              x: d["spread_duration"],
              y: d["z_spread"],
              name: d.ticker,
              instrument: d.instrument,
              country: d.country,
              creditBucket: d["sub_credit_bucket"],
              EMDSector: d["emd_sector"],
              matureYear: d.instrument.split(/\s+/).pop()
            }))
            .filter((d) => d.y > 0),
          (d) => d[by]
        )
      ).map((d) => ({
        name: d[0][by],
        type: "scatter",
        data: d
      }))
    },
    chartOptions() {
      const vm = this
      const ymax = this.showAll
        ? null
        : Math.max(...filterOutliers(vm.series.reduce((cur, iter) => [...cur, ...iter.data], []).map((d) => d.y)))

      return {
        chart: {
          zoomType: "xy",
          height: vm.maximized && vm.$vuetify.display.lgAndUp ? "640px" : "360px"
        },
        xAxis: { title: { text: "Duration" } },
        yAxis: {
          title: { text: "Spread" },
          max: ymax
        },
        series: this.series,
        legend: { enabled: true },
        tooltip: {
          headerFormat: "",
          valueDecimals: 1,
          pointFormatter() {
            const items = [
              `<b>${this.instrument}</b>`,
              `Spread Duration: <b>${vm.fmtNum(this.x, 1)}</b>`,
              `Spread: <b>${Math.round(this.y)}</b>`
            ] //Math.round(null) is 0, yak!!
            return items.join("<br/>")
          }
        },
        plotOptions: {
          scatter: {
            dataLabels: {
              enabled: true,
              allowOverlap: true,
              format: "{point.name} {point.matureYear}"
            }
          },
          series: { dataLabels: { enabled: false } }
        }
      }
    }
  },
  async mounted() {
    const instrument = await loadIssuerInfo(this.apiRepo, this.issuer)

    const emdSector = instrument.emd_sector
    if (emdSector !== "Not Screened") {
      const dataUrl = `/api/corp_bonds?emd_sector=${encodeURIComponent(emdSector)}`
      this.rawData = await this.getData(dataUrl)
    } else {
      const el = this.$refs.corpOpportunitySet
      if (el.chart) {
        el.chart.hideNoData()
        el.chart.showNoData("Corporation does not have an assigned EMD sector. No opportunity group is available.")
      }
    }
  },
  methods: {
    fmtNum,
    async getData(url) {
      let response
      try {
        const instance = await this.apiRepo.makeServiceAPIClient()
        response = await instance.get(url)
      } catch {
        throw new Error("failed to load opportunity set data")
      }
      const { data, status } = response

      if (status !== 404) {
        this.loading = false
        return data
      } else {
        this.log.error("failed to get corp opportunity set data")
        return []
      }
    }
  }
}
</script>

<style>
.chart-placeholder {
  font-size: 14px;
  min-height: 360px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #9c9c9c;
}
</style>
