<template>
  <div class="color-scaled-score" :style="dynamicStyle">
    {{ score }}
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue"

import { getColorScaleD3 } from "@/utils/colorScale"

const { score, size = 25 } = defineProps<{
  score: number | string
  size?: number
}>()

const color = computed(() => {
  const c = parseInt(score as string)
  if (isNaN(c)) {
    return "#ffbc75"
  }
  return getColorScaleD3(c)
})

const dynamicStyle = computed(() => {
  return {
    color: color.value,
    borderColor: color.value,
    width: `${size}px`,
    height: `${size}px`,
    fontSize: `${size / 1.625}px`,
    borderRadius: `${size / 2}px`
  }
})
</script>

<style>
.color-scaled-score {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 600;
  letter-spacing: -0.5px;
  color: currentColor;
  border-style: solid;
  border-width: 2px;
}
</style>
