import { createRouter, createWebHistory } from "vue-router"
import EconOverview from "@/views/EconOverview.vue"
import EconFundamentals from "@/views/EconFundamentals.vue"
import EconValuationTool from "@/views/EconValuationTool.vue"
import EconTechnicals from "@/views/EconTechnicals.vue"
import EconESG from "@/views/EconESG.vue"

import CorpValuation from "@/views/CorpValuation.vue"
import CorpOverview from "@/views/CorpOverview.vue"
import CorpModelView from "@/views/CorpModelView.vue"
import CorpESG from "@/views/CorpESG.vue"

import Home from "@/views/Home.vue"

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: { transitionName: "slide", menuOrder: 0 }
  },
  {
    path: "/sovereign",
    name: "sovereign-home",
    component: () => import("../views/SovereignHome.vue")
  },
  {
    path: "/corp",
    name: "corp-home",
    component: () => import("../views/CorpHome.vue")
  },
  {
    path: "/taa-scorecard",
    name: "top-down-taa-scorecard",
    component: () => import("../views/taa/Taa.vue")
  },
  {
    path: "/econ-summary",
    name: "top-down-econ-summary",
    component: () => import("../views/topDown/EconSummary.vue")
  },
  {
    path: "/portfolio-management",
    name: "portfolio-management",
    beforeEnter() {
      const url = "http://emdrebaltool.blairnet.net/"
      //since it is a intermediate page for redireaction,
      //use location.replace here to avoid http://host/portfolio-management to be saved in the history
      //which will cause the back button to go back to the intermediate page
      location.replace(url)
    }
  },
  {
    path: "/portfolio-management-new/:clientCode?",
    name: "portfolio-management-new",
    component: () => import("../views/PortfolioManagement.vue")
  },
  {
    path: "/investable-universe",
    name: "investable-universe",
    component: () => import("../views/InvestableUniverse.vue")
  },
  {
    path: "/countries/:region/econ",
    alias: "/countries/:region/sovereign",
    component: () => import("../views/Sovereign.vue"),
    name: "econ",
    props: true,
    children: [
      { name: "econ-overview", path: "", component: EconOverview, props: true },
      { name: "econ-fundamentals", path: "fundamentals", component: EconFundamentals, props: true },
      { name: "econ-valuations", path: "valuations", component: EconValuationTool, props: true },
      { name: "econ-technicals", path: "technicals", component: EconTechnicals, props: true },
      {
        name: "econ-sustainability",
        path: "sustainability",
        component: EconESG,
        props: true
      }
    ].map((d, i) => ({
      ...d,
      meta: {
        //transitionName: "slide",
        menuOrder: 20 + i,
        keyGen($route) {
          // remove the last segment for nested route
          // note fullPath is start with /, so need slice 4
          const parentKey = $route.fullPath.split("/").slice(0, 4).join("/")
          return parentKey
        }
      }
    }))
  },
  {
    path: "/countries/:region/equity",
    name: "equity",
    component: () => import("../views/EquityHome.vue"),
    props: true,
    meta: { transitionName: "slide", menuOrder: 50 }
  },
  {
    path: "/econ-export",
    name: "export",
    component: () => import("../views/EconDataExport.vue"),
    props: true
  },
  {
    path: "/corps/:issuer?",
    name: "corp-detail",
    component: () => import("../views/CorpDetail.vue"),
    props: true,
    meta: { transitionName: "slide", menuOrder: 40 },
    children: [
      { name: "corp-overview", path: "overview", component: CorpOverview, props: true },
      { name: "corp-risk-model", path: "risk-model", component: CorpModelView, props: true },
      { name: "corp-esg", path: "esg", component: CorpESG, props: true },
      {
        path: "esg",
        component: () => import("../views/corpEsg/Home.vue"),
        props: true,
        children: [
          {
            name: "corp-esg-beta",
            path: "",
            props: true,
            redirect: {
              name: "corp-esg-overview"
            }
          },
          {
            path: "overview",
            name: "corp-esg-overview",
            component: () => import("../views/corpEsg/Overview.vue"),
            props: true
          },
          {
            path: "scores",
            name: "corp-esg-issuer-scores",
            component: () => import("../views/corpEsg/IssuerScores.vue"),
            props: true
          },
          {
            path: "factor-materialities/:sectorGroup",
            name: "issuer-corp-esg-factor-materialities",
            component: () => import("../views/corpEsg/FactorMaterialities.vue"),
            props(route) {
              return {
                sectorGroup: route.params.sectorGroup
              }
            }
          }
        ].map((d, i) => ({
          ...d,
          meta: {
            transitionName: "slide",
            menuOrder: 60 + i
          }
        }))
      },
      {
        name: "corp-valuation",
        path: "valuation",
        component: CorpValuation,
        props: true
      }
    ].map((d, i) => ({
      ...d,
      meta: {
        transitionName: "slide",
        menuOrder: 40 + i,
        keyGen($route) {
          const parentKey = $route.fullPath.split("/").slice(0, 3).join("/")
          return parentKey
        }
      }
    }))
  },
  {
    path: "/corp-sectors",
    exact: true,
    name: "corp-sectors",
    component: () => import("../views/CorpHome.vue"),
    props: true,
    meta: { transitionName: "slide", menuOrder: 30 }
  },
  {
    path: "/corp-sectors/:sector",
    exact: true,
    name: "corp-sector",
    component: () => import("../views/CorpSector.vue"),
    props: true,
    meta: { transitionName: "slide", menuOrder: 31 }
  },

  {
    path: "/factor-materialities/:sectorGroup",
    name: "corp-esg-factor-materialities",
    meta: { transitionName: "slide", menuOrder: 34 },
    component: () => import("../views/corpEsg/FactorMaterialities.vue"),
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  scrollBehavior(_to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

export default router
