<template>
  <div class="home">
    <div class="home-content">
      <div class="hero-section">
        <div class="hero-text">
          <h2>
            Our focus on emerging market debt instruments is designed to help you achieve your asset allocation goals
            through our strategic approach.
          </h2>
        </div>
      </div>
      <div class="home-main">
        <div class="products">
          <div class="product-card product-card-sovereign">
            <div class="product-text">
              <div class="sub-hero-img">
                <img src="/img/sovereign.svg" alt="sovereign" />
              </div>
              <h1 class="sub-hero-title">Sovereign</h1>
              <div class="sub-hero-text">
                Invests in government-issued securities of developing countries to provide potential long-term returns
                and diversification benefits to investors.
                <div class="product-link">
                  <a
                    href="https://www.williamblairfunds.com/funds/individual/fundid_30/share_class_i/emerging_markets_debt_fund/overview.fs"
                    _target="blank"
                  >
                    Learn More &gt;
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="product-card product-card-corp">
            <div class="product-text">
              <div class="sub-hero-img">
                <img src="/img/corp.svg" alt="corp" />
              </div>
              <h1 class="sub-hero-title">Corporate</h1>
              <div class="sub-hero-text">
                Offers exposure to high-quality fixed-income securities issued by corporations in developing countries,
                potentially providing attractive yields and diversification opportunities to investors.
                <div class="product-link">
                  <a
                    href="https://www.williamblairfunds.com/funds/individual/fundid_30/share_class_i/emerging_markets_debt_fund/overview.fs"
                    _target="blank"
                  >
                    Learn More &gt;
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="hero-overlay-globe">
          <Globe class="globe" :region="region" :heatmap-data="heatmapData"></Globe>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, inject, onBeforeMount, computed } from "vue"

import Globe from "@/components/common/GlobeCanvas.vue"
import { BetaBucketHc, type SovereignUniverseEntry } from "@/utils/SovereignUniverse"
import type SovereignUniverse from "@/utils/SovereignUniverse"

const sovereignUniverse: SovereignUniverse = inject("sovereignUniverse")!

const regions = ref<SovereignUniverseEntry[]>([])
const region = ref<SovereignUniverseEntry>()
const bucketColors = { [BetaBucketHc.High]: "#405A55", [BetaBucketHc.Medium]: "#50897B", [BetaBucketHc.Low]: "#C4D7D2" }

const heatmapData = computed(() =>
  regions.value.reduce<Record<number, string>>((obj, cur) => {
    if (cur.isoNumericId <= 0) return obj
    if (!cur.betaBucketHc) return obj

    const color = bucketColors[cur.betaBucketHc]
    if (!color) return obj

    obj[cur.isoNumericId] = color
    return obj
  }, {})
)

onBeforeMount(async () => {
  regions.value = sovereignUniverse.filter((d) => d.isoAlpha3 !== "BRB" && d.isoAlpha3 !== "BMU") //these are not in topojson data and will cause globe spin fail
})

setInterval(() => {
  region.value = regions.value[Math.floor(Math.random() * regions.value.length)]
}, 5000)
</script>

<style scoped>
.home {
  font-family: "Chronicle Display A", sans-serif;
  background-image: url(/img/landing-splash-1.jpg);
  background-size: cover;
  height: 100% !important;
}
.home-content {
  max-width: 1920px;
  margin: 0 auto;
  padding: 32px;
}
.hero-section {
  width: 100%;
}
.hero-text {
  font-size: 32px;
  width: 100%;
  padding: 64px 0;
  color: #004a80;
}
.home-main {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
}
.sub-hero-title {
  font-size: 28px;
  color: #004a80;
}
.sub-hero-text {
  font-family: Cambria, Georgia, Times, "Times New Roman", serif;
  font-size: 16px;
  color: #000;
}
.sub-hero-img img {
  width: 120px;
}
.hero-overlay-globe {
  display: grid;
  place-content: center;
}
.globe {
  width: 600px;
  height: 600px;
}
.products {
  height: 600px;
  display: grid;
  place-items: center;
  gap: 32px;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
}
.product-card {
  position: relative;
  border-radius: 10px;
  padding: 32px;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  height: 360px;
}

.product-text {
  font-size: 24px;
}

/* hide globe on medium screen */
@media (max-width: 768px) {
  .home-main {
    grid-template-columns: 1fr;
  }
  .hero-overlay-globe {
    display: none;
  }
  .hero-text {
    font-size: 20px;
  }
}
</style>
