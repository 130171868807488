import { scaleLinear } from "d3-scale"

export function getColorScale(score: number, isDarkTheme: boolean) {
  if (isNaN(score)) {
    return "#ffbc75"
  }

  if (score >= 60) return "#4CAF50"
  if (score >= 40) return isDarkTheme ? "#c7c7c7" : "#1c1c1c"
  if (score >= 0) return "#ff002f"
  return "#ffbc75"
}

export function getColorScaleD3(score: number) {
  const lowerMax = "#db4b4b"
  const upperMax = "#50b432"

  return scaleLinear([0, 100], [lowerMax, upperMax])(score).toString()
}
